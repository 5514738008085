import Component from "../../basic/Component.js";

export default class CardAjax extends Component {
    setElements() {
        this.$element.classList.add("mykl-card");
        this.template = this.$element.innerHTML;
    }

    setTemplate() {
        if(this.template) {
            const { data = [], replace = {}, other = [] } = this.$data;
            return data.map((item, index) => {
                let originHTML = this.template;
                [...Object.keys(item), ...other].map((key) => {
                    if (replace[key]) {
                        return originHTML = originHTML.replaceAll('$' + key, replace[key](item, index));
                    }
                    return originHTML = originHTML.replaceAll('$' + key, item[key])

                })
                return originHTML;
            }).join("");
        }
    }

    render() {
        this.$element.innerHTML = this.setTemplate();
    }

    setData(data) {
        this.$data = { ...this.$data, ...data};
        this.render();
    }
}